window.wdst = window.wdst || {};

/**
 * Based on the jQuery.cookie and js.cookie, etc. Slimmed and modernized to fit
 * our purposes.
 *
 * @license MIT
 */
(function($, wdst){
    var cookies = {}, src = document.cookie.split(';');

    // Parse cookies once on load, not each attempt to read them.
    for (var k = src.length - 1; k >= 0; k--) {
        src[k] = $.trim(src[k]);

        if (src[k]) {
            var cookie = src[k].split('=');

            for (var j = cookie.length - 1; j >= 0; j--) {
                cookie[j] = decodeURIComponent($.trim(cookie[j]));
            }

            if (cookie[0]) {
                cookies[cookie[0]] = cookie[1] ? cookie[1] : null;
            }
        } else {
            src.splice(k, 1);
        }
    }

    var encodeKey = function (string) {
        var key = encodeURIComponent(String(string));
        key = key.replace(/%(2[346B]|5E|60|7C)/g, decodeURIComponent);
        return key.replace(/[()]/g, escape);
    };

    var encodeVal = function (string) {
        var value = encodeURIComponent(String(string));
        return value.replace(/%(2[346BF]|3[ACEDF]|40|5[BDE]|60|7[BDC])/g, decodeURIComponent);
    };

    wdst.cookie = {
        /**
         * Gets a single cookie by name.
         *
         * @param {string} name
         * @returns {string}
         */
        get: function (name) {
            return cookies[encodeKey(name)];
        },

        /**
         * Sets a cookie with the following parameters.
         *
         * @param {string} name - The name (snake-case recommended)
         * @param {string} value - The value (JSON encode if necessary)
         * @param {int} [duration] - TTL in seconds
         * @param {string} [domain] - The domain name
         * @param {string} [path=/] - The URI path
         * @param {bool} [secure=false] - HTTPS/Secure flag
         */
        set: function (name, value, duration, domain, path, secure) {
            var key = encodeKey(name);
            value = encodeVal(value);

            document.cookie = [
                key + '=' + value,
                duration ? '; expires=' + (new Date(Date.now() + duration * 1000)).toUTCString() : '',
                domain ? '; domain=' + domain : '',
                '; path=' + (path || '/'),
                secure ? '; secure' : ''
            ].join('');

            cookies[key] = value;
        },

        /**
         * Deletes a single cookie.
         *
         * @param {string} name
         */
        del: function (name) {
            wdst.cookie.set(name, '', -1);
        }
    };
})(jQuery, wdst);
